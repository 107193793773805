<template>
  <div>
    <div
      class="grid gap-x-1.5 gap-y-3 md:gap-3"
      style="grid-template-columns: auto 1fr; grid-template-rows: auto 1fr"
    >
      <RouterLink
        :to="{ name: 'EventView', params: { id: value.id } }"
        class="group peer relative col-span-1 flex aspect-default h-fit w-[266px] max-w-[161px] items-center justify-center overflow-hidden bg-secondary md:row-span-2 md:max-w-[266px]"
      >
        <UiPictureMask />
        <UiPicture
          v-for="image in 2"
          :key="'image' + image"
          :src="value.image"
          :alt="value.title"
          :options="{ height: 168 * 1.5 }"
          :img-classes="image > 1 ? 'object-cover' : ''"
          :class="
            image > 1
              ? 'absolute z-0 size-full object-cover opacity-20 blur-lg inset-center'
              : 'relative z-10 h-full max-h-full max-w-full object-contain transition ease-out group-hover:scale-105'
          "
          lazy
        >
          <template v-if="image < 2" #default-image>
            <UiBackupImage :value="value.title" :alt="value.title" />
          </template>
        </UiPicture>
      </RouterLink>
      <RouterLink
        type="button"
        :to="{ name: 'EventView', params: { id: value.id } }"
        class="peer-hover:!text-button-primary-link-hover group h-fit !px-3 !pt-1 text-left !text-text-main"
      >
        <UiTitle
          severity="h5"
          class="group-hover:text-button-primary-link-hover inline text-inherit"
        >
          {{ value.title }}
        </UiTitle>
        <span v-if="value.ageCategory" class="inline pl-1 text-sm text-text-secondary">{{
          value.ageCategory
        }}</span>

        <UiIcon name="arrow-right" class="inline-flex size-3 text-inherit transition-transform" />
      </RouterLink>
      <ul class="col-span-2 flex h-fit flex-wrap gap-2 pb-1 md:col-span-1 md:gap-3">
        <li v-for="seance in seances" :key="seance.id" class="">
          <RouterLink
            :to="{ name: 'SeanceView', params: { id: seance.id } }"
            class="flex flex-col rounded-xl border border-primary px-3 py-2 font-medium shadow-primary hover:shadow-secondary"
          >
            <UiDate :value="seance.beginsAt" template="HH:mm" class="text-sm" />
            <p v-if="seance.ticketsLeft" class="text-xs text-primary">
              <span class="font-normal">от</span> {{ toRub(seance.priceRange.min) }}
            </p>
            <p v-else class="text-xs text-text-main">распродано</p>
          </RouterLink>
        </li>
        <li v-if="needMoreButton">
          <button
            type="button"
            class="flex h-[54px] items-center rounded-xl border border-primary px-3 py-2 text-sm font-medium shadow-primary hover:shadow-secondary"
            @click="emit('showMore')"
          >
            ещё <UiIcon name="chevron-down" class="size-4.5 -rotate-90" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { toRub, useBreakpoint } from '@/6_shared/lib'
import { UiPicture, UiTitle, UiIcon, UiDate, UiBackupImage, UiPictureMask } from '@/6_shared/ui'
import type { Event } from '../../model'

type PropType = {
  value: Event
}
type EmitType = {
  (e: 'showMore'): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()
const {
  lg: [isLgAndUp]
} = useBreakpoint()

const seances = computed(() => {
  const MAX_SEANCES = isLgAndUp.value ? 10 : 8

  return props.value.seances.filter((_, index) => index <= MAX_SEANCES)
})
const needMoreButton = computed(() => props.value.seances.length > seances.value.length)
</script>
